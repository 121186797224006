import type { Meta } from "~/types/layout";

import { setActiveRoute } from "~/composables/routes";

const meta = reactive<Meta>({
	title: "",
	description: "",
	back: ""
});

export function useMeta (): Meta {
	return meta;
}

export function setMeta (
	title: Meta["title"],
	description: Meta["description"],
	activeMenu = "",
	back = ""
): void {
	const { t } = useI18n();
	meta.title = title;
	meta.description = description;
	if (back && back !== "") {
		meta.back = back;
	} else {
		meta.back = "";
	}
	// Update the document title
	document.title = `${t(title)} - Console Leviia`;

	// Update active route
	setActiveRoute(activeMenu);
}
